<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import {mapActions} from 'vuex'
import "prismjs";
import "prismjs/themes/prism.css";
import {
  required,
  email,
  helpers,
  numeric
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      isSubmited:false,
      loader:false,
      disabled:false,
      user:{
        name:"",
        email:"",
        phone:"",
        language:"english",
        visible_password:"",
        role_id:3,
        business_name:"",
        business_type:"",
        bank_account:"",
        address:"",
        city:"",
        state:"",
        zip:"",
        country:"india",
      }
    };
  },
    validations: {
    user:{
      name: {
       required: helpers.withMessage("Name is required", required),
      },
      email: {
        required: helpers.withMessage("Email is required", required),
        email: helpers.withMessage("Please enter valid email", email),
      },
      phone: {
        required: helpers.withMessage("Phone is required", required),
        numeric: helpers.withMessage("Please enter only numbers", numeric),
      },
      language: {
        required: helpers.withMessage("Language is required", required),
      },
      visible_password: {
        required: helpers.withMessage("Password is required", required),
      },
      role_id: {
        required: helpers.withMessage("Role is required", required),
      },
      // business_name: {
      //   required: helpers.withMessage("Business Name is required", required),
      // },
      country: {
        required: helpers.withMessage("Country is required", required),
      },
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  methods:{
    ...mapActions({
      addUserDb:'users/addUser',
      getUserById:'users/getUserById',
      updateUserDb:'users/updateUser',
    }),
    addUser(){
         this.isSubmited = true;
          this.v$.user.$touch();
          if (this.v$.user.$invalid) {
            return;
          } 
          this.loader=true
          this.disabled=true
          this.addUserDb(this.user).then((res)=>{
            this.loader=false
            this.disabled=false
            if(res.data.status){
                this.$toast.open({
                  message: "User added.",
                  type: "success",
                })
                this.$router.push({ name: "Users" });
            }else{
              let message=res.data.message
              this.$toast.open({
                  message: message,
                  type: "error",
                })
            }
          }).catch((error)=>{
            this.loader=false
            this.disabled=false
              if(error.response && error.response.status == 422 && error.response.data && error.response.data.data.email){
                this.$toast.open({
                    message: 'The email has already been taken.',
                    type: "error",
                  }) 
              }else if(error.response && error.response.status == 422 && error.response.data && error.response.data.data.phone){
                this.$toast.open({
                    message: 'The phone has already been taken.',
                    type: "error",
                  }) 
              }else{
                  this.$toast.open({
                    message: 'Server Error',
                    type: "error",
                  })  
              }
          })
        
    },
    updateUser(){
         this.isSubmited = true;
          this.v$.user.$touch();
          if (this.v$.user.$invalid) {
            return;
          } 
          this.loader=true
          this.disabled=true
          this.updateUserDb(this.user).then((res)=>{
            this.loader=false
            this.disabled=false
            if(res.data.status){
                this.$toast.open({
                  message: "User updated.",
                  type: "success",
                })
                this.$router.push({ name: "Users" });
            }else{
              // let message=res.data.message
              // this.$toast.open({
              //     message: message,
              //     type: "error",
              //   })
            }
          }).catch((error)=>{
            this.loader=false
            this.disabled=false
            if(error.response && error.response.status == 422 && error.response.data && error.response.data.data.email){
              this.$toast.open({
                  message: 'The email has already been taken.',
                  type: "error",
                }) 
            }else if(error.response && error.response.status == 422 && error.response.data && error.response.data.data.phone){
              this.$toast.open({
                  message: 'The phone has already been taken.',
                  type: "error",
                }) 
            }else{
                this.$toast.open({
                  message: 'Server Error',
                  type: "error",
                })  
            }
          })
        
    }
  },
  mounted() {
    if(this.$route.name == 'EditUser' && this.$route.params.id){
        this.getUserById(this.$route.params.id).then((res)=>{
          if(res.data.status){
            this.user=res.data.data.user
          }else{
            this.$router.push({name:"Users"})
          }
        }).catch(()=>{
            this.$router.push({name:"Users"})
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="user.id ? $t('user.update-user') : $t('user.add-user')" />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{user.id ? $t('user.update-user') : $t('user.add-user')}}</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="nameInput" class="form-label">{{ $t('user.name') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="nameInput"
                      :placeholder="$t('user.enter-your-name')"
                      v-model="user.name"
                      :class="{
                        'is-invalid': isSubmited && v$.user.name.$error,
                      }"
                    />
                      <div
                          v-for="(item, index) in v$.user.name.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="leaveemails" class="form-label">{{ $t('user.email') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="email"
                      class="form-control"
                      id="leaveemails"
                      :placeholder="$t('user.enter-your-email')"
                      v-model="user.email"
                      :class="{
                        'is-invalid': isSubmited && v$.user.email.$error,
                      }"
                    />
                      <div
                          v-for="(item, index) in v$.user.email.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="phone" class="form-label">{{ $t('user.phone') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="phone"
                      :placeholder="$t('user.enter-your-phone')"
                      v-model="user.phone"
                      :class="{
                        'is-invalid': isSubmited && v$.user.phone.$error,
                      }"
                    />
                      <div
                          v-for="(item, index) in v$.user.phone.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="phone" class="form-label">{{ $t('user.language') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <div class="mt-4 mt-lg-0">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions1"
                          id="lang-english"
                          value="english"
                          v-model="user.language"
                          :class="{
                            'is-invalid': isSubmited && v$.user.language.$error,
                          }"
                        />
                        <label class="form-check-label" for="lang-english"
                          >English</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions2"
                          id="lang-arabic"
                          value="arabic"
                          v-model="user.language"
                          :class="{
                            'is-invalid': isSubmited && v$.user.language.$error,
                          }"
                        />
                        <label class="form-check-label" for="lang-arabic"
                          >Arabic</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="password" class="form-label">{{ $t('user.password') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="password"
                      :placeholder="$t('user.enter-your-password')"
                      v-model="user.visible_password"
                      :class="{
                          'is-invalid': isSubmited && v$.user.visible_password.$error,
                        }"
                      />
                      <div
                          v-for="(item, index) in v$.user.visible_password.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="business_name" class="form-label"
                      >{{ $t('user.business-name') }}</label
                    >
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="business_name"
                      :placeholder="$t('user.enter-business-name')"
                      v-model="user.business_name"
                    />
                  </div>
                </div>
                  <div class="row mb-4">
                    <div class="col-lg-3">
                      <label for="phone" class="form-label">{{ $t('user.business-type') }}</label>
                    </div>
                    <div class="col-lg-9">
                      <div class="mt-4 mt-lg-0">
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="business_type"
                            id="private-limited"
                            value="private-limited"
                            v-model="user.business_type"
                          />
                          <label class="form-check-label" for="private-limited"
                            >{{ $t('user.private-limited') }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="business_type"
                            id="public-limited"
                            value="public-limited"
                            v-model="user.business_type"
                          />
                          <label class="form-check-label" for="public-limited"
                            >{{ $t('user.public-limited') }}</label
                          >
                        </div>
                        <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="business_type"
                            id="propertiership"
                            value="propertiership"
                            v-model="user.business_type"
                          />
                          <label class="form-check-label" for="propertiership"
                            >{{ $t('user.propertiership') }}</label
                          >
                        </div>
                      </div>
                    </div>
                </div>
                <!-- <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="bank_account" class="form-label">Bank Account</label>
                  </div>
                  <div class="col-lg-9">
                    <textarea class="form-control" id="bank_account" rows="3" placeholder="Enter your Bank Account" v-model="user.bank_account"></textarea>
                  </div>
                </div> -->
              </div>

              <div class="col-6">
               
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="phone" class="form-label">{{ $t('user.role') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <div class="mt-4 mt-lg-0">
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="role-radio"
                          id="super-admin"
                          value="1"
                          v-model="user.role_id"
                          :class="{
                          'is-invalid': isSubmited && v$.user.role_id.$error,
                          }"
                        />
                        <label class="form-check-label" for="super-admin"
                          >{{ $t('user.super-admin') }}</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="role-radio"
                          id="team"
                          value="2"
                          v-model="user.role_id"
                          :class="{
                          'is-invalid': isSubmited && v$.user.role_id.$error,
                          }"
                        />
                        <label class="form-check-label" for="team"
                          >{{ $t('user.team') }}</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="role-radio"
                          id="user"
                          value="3"
                          v-model="user.role_id"
                          :class="{
                          'is-invalid': isSubmited && v$.user.role_id.$error,
                          }"
                        />
                        <label class="form-check-label" for="user"
                          >{{ $t('user.user') }}</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="role-radio"
                          id="consultant"
                          value="4"
                          v-model="user.role_id"
                          :class="{
                          'is-invalid': isSubmited && v$.user.role_id.$error,
                          }"
                        />
                        <label class="form-check-label" for="user"
                          >Consultant </label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="role-radio"
                          id="qa"
                          value="5"
                          v-model="user.role_id"
                          :class="{
                          'is-invalid': isSubmited && v$.user.role_id.$error,
                          }"
                        />
                        <label class="form-check-label" for="user"
                          >QA</label
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="address" class="form-label">{{ $t('user.address') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <textarea class="form-control" id="address" rows="3" :placeholder="$t('user.enter-your-address')" v-model="user.address"></textarea>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="city" class="form-label"
                      >{{ $t('user.city') }}</label
                    >
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="city"
                      :placeholder="$t('user.enter-city')"
                      v-model="user.city"
                    />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="state" class="form-label"
                      >{{ $t('user.state') }}</label
                    >
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="state"
                      :placeholder="$t('user.enter-state')"
                      v-model="user.state"
                    />
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="zipcode" class="form-label"
                      >{{ $t('user.zip') }}</label
                    >
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="zipcode"
                      :placeholder="$t('user.enter-zip')"
                      v-model="user.zip"
                    />
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="country" class="form-label"
                      >{{ $t('user.country') }}</label
                    >
                  </div>
                  <div class="col-lg-9">
                    <Multiselect
                      v-model="user.country"
                      :class="{
                      'is-invalid': isSubmited && v$.user.country.$error,
                      }"
                      :close-on-select="true"
                      :create-option="true"
                      :clearable="false"
                       :searchable="true"
                      :options="[
                        { value: 'india', label: 'India' },
                        { value: 'uae', label: 'UAE' },
                      ]"
                    />
                    <div
                          v-for="(item, index) in v$.user.country.$errors"
                          :key="index"
                          class="invalid-feedback"
                        >
                          <span v-if="item.$message">{{ item.$message }}</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-end float-end">
                <button class="btn btn-primary align-items-center d-flex justify-content-center" v-if="!user.id" :disabled="disabled" @click="addUser">
                    {{ $t('user.add-user') }}
                    <div class="spinner-border loader-setup" role="status" v-if="loader">
                        <span class="sr-only">{{ $t('t-loading') }}</span>
                      </div>
                  </button>
                  <button class="btn btn-primary align-items-center d-flex justify-content-center" v-else :disabled="disabled" @click="updateUser">
                    {{ $t('user.update-user') }}
                    <div class="spinner-border loader-setup" role="status" v-if="loader">
                        <span class="sr-only">{{ $t('t-loading') }}</span>
                      </div>
                  </button>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <!-- end col -->
    </div>

    <!--end row-->
  </Layout>
</template>
<style>
.multiselect.is-invalid {
    border: 1px solid red !important;
}
</style>